
import { defineComponent, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '@/store'
import { useMeta } from 'vue-meta'
import axios from 'axios'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import getDeviceToken from '../../functions/getDeviceToken'

import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'
import SwiperCore, { Autoplay, Pagination, Navigation, Thumbs } from 'swiper/core'
SwiperCore.use([Autoplay, Pagination, Navigation, Thumbs])

export default defineComponent({
	components: {
		Swiper,
		SwiperSlide,
	},
	setup() {
		const { meta } = useMeta({
			link: [
				{
					rel: 'canonical',
					href: 'https://www.ai-credit.com/',
				},
			],
			title: '【ポモチ】毎日の買物を楽しくする、ポイ活応援・お得アプリ',
			description:
				'ポモチはお買い物のポイント還元を最大化するお得情報アプリです。クレジットカード・ポイントカード・スマートフォン決済の組み合わせで還元率は変動し、お店ごとにお得な支払い方法は異なります。あなたがよく利用するお店、交通手段、家族構成、ライフスタイルと生活圏によって決済方法選びは変わります。あなたのライフスタイルに合った決済選びをしましょう。ポモチはあなたが利用するお店の毎日のお得なキャンペーン情報やお得な情報もお届けします。',
			og: {
				title: '【ポモチ】毎日の買物を楽しくする、ポイ活応援・お得アプリ',
				description:
					'ポモチはお買い物のポイント還元を最大化するお得情報アプリです。クレジットカード・ポイントカード・スマートフォン決済の組み合わせで還元率は変動し、お店ごとにお得な支払い方法は異なります。あなたがよく利用するお店、交通手段、家族構成、ライフスタイルと生活圏によって決済方法選びは変わります。あなたのライフスタイルに合った決済選びをしましょう。ポモチはあなたが利用するお店の毎日のお得なキャンペーン情報やお得な情報もお届けします。',
				url: window.location.href,
				image: 'https://www.ai-credit.com/apple-touch-icon.png',
				type: 'website',
				site_name: 'ポモチ',
				locale: 'ja',
			},
			twitter: {
				title: '【ポモチ】毎日の買物を楽しくする、ポイ活応援・お得アプリ',
				description:
					'ポモチはお買い物のポイント還元を最大化するお得情報アプリです。クレジットカード・ポイントカード・スマートフォン決済の組み合わせで還元率は変動し、お店ごとにお得な支払い方法は異なります。あなたがよく利用するお店、交通手段、家族構成、ライフスタイルと生活圏によって決済方法選びは変わります。あなたのライフスタイルに合った決済選びをしましょう。ポモチはあなたが利用するお店の毎日のお得なキャンペーン情報やお得な情報もお届けします。',
				image: 'https://www.ai-credit.com/android-chrome-512x512.png',
			},
			bodyAttrs: {
				class: 'shops_common shops_home',
			},
		})

		const branchesCategories = reactive([
			{
				name: 'コンビニ',
				branches: [
					{
						id: 344683,
						shop_name: 'セブンイレブン',
						url: 'https://www.ai-credit.com/article/seven-eleven/',
						plans: [],
					},
					{
						id: 2467,
						shop_name: 'ローソン',
						url: 'https://www.ai-credit.com/article/lawson/',
						plans: [],
					},
					{
						id: 13899935,
						shop_name: 'ファミリーマート',
						url: 'https://www.ai-credit.com/article/family-mart/',
						plans: [],
					},
					{
						id: 6271,
						shop_name: 'ミニストップ',
						url: 'https://www.ai-credit.com/article/mini-stop/',
						plans: [],
					},
					{
						id: 6569,
						shop_name: 'デイリーヤマザキ',
						url: 'https://www.ai-credit.com/article/dairyyamazaki-shiharai/',
						plans: [],
					},
					{
						id: 57403628,
						shop_name: 'セイコーマート',
						url: 'https://www.ai-credit.com/article/seikomart-shiharai/',
						plans: [],
					},
				],
			},
			{
				name: 'スーパー',
				branches: [
					{
						id: 7238,
						shop_name: 'イトーヨーカドー',
						url: 'https://www.ai-credit.com/article/itoyokado/',
						plans: [],
					},
					{
						id: 57451624,
						shop_name: 'まいばすけっと',
						url: 'https://www.ai-credit.com/article/my-basket/',
						plans: [],
					},
					{
						id: 57426033,
						shop_name: 'オーケー',
						url: 'https://www.ai-credit.com/article/okstore-otoku-shiharai/',
						plans: [],
					},
					{
						id: 57275404,
						shop_name: 'マルエツ',
						url: 'https://www.ai-credit.com/article/maruetsu/',
						plans: [],
					},
					{
						id: 8565,
						shop_name: 'イオン',
						url: 'https://www.ai-credit.com/article/aeon/',
						plans: [],
					},
					{
						id: 7326,
						shop_name: '西友',
						url: 'https://www.ai-credit.com/article/seiyu/',
						plans: [],
					},
					{
						id: 55443891,
						shop_name: '業務スーパー',
						url: 'https://www.ai-credit.com/article/gyomu-super/',
						plans: [],
					},
				],
			},
			{
				name: 'ドラッグストア',
				branches: [
					{
						id: 57401104,
						shop_name: 'マツモトキヨシ',
						url: 'https://www.ai-credit.com/article/matsumoto-kiyoshi/',
						plans: [],
					},
					{
						id: 57343073,
						shop_name: 'ココカラファイン',
						url: 'https://www.ai-credit.com/article/cocokarafine-payment-method/',
						plans: [],
					},
					{
						id: 57394975,
						shop_name: 'くすりの福太郎',
						url: 'https://www.ai-credit.com/article/kusurino-fukutaro/',
						plans: [],
					},
					{
						id: 57319663,
						shop_name: 'トモズ',
						url: 'https://www.ai-credit.com/article/tomods-ponta/',
						plans: [],
					},
					{
						id: 57392620,
						shop_name: 'セイムス',
						url: 'https://www.ai-credit.com/article/seims/',
						plans: [],
					},
					{
						id: 55339974,
						shop_name: 'ウエルシア',
						url: 'https://www.ai-credit.com/article/welcia-yakkyoku/',
						plans: [],
					},
				],
			},
			{
				name: '回転寿司',
				branches: [
					{
						id: 57349371,
						shop_name: 'スシロー',
						url: 'https://www.ai-credit.com/article/akindo-sushiro/',
						plans: [],
					},
					{
						id: 13695413,
						shop_name: 'はま寿司',
						url: 'https://www.ai-credit.com/article/hamazushi/',
						plans: [],
					},
					{
						id: 13695409,
						shop_name: 'くら寿司',
						url: 'https://www.ai-credit.com/article/kurasushi/',
						plans: [],
					},
					{
						id: 57402703,
						shop_name: 'かっぱ寿司',
						url: 'https://www.ai-credit.com/article/kappazusi-otoku-shiharai/',
						plans: [],
					},
					{
						id: 13906461,
						shop_name: '銚子丸',
						url: 'https://www.ai-credit.com/article/choushimaru-otoku-point/',
						plans: [],
					},
					{
						id: 13907631,
						shop_name: '三崎港',
						url: 'https://www.ai-credit.com/article/mizakikou-shiharaihouhou/',
						plans: [],
					},
				],
			},
			{
				name: 'ファミレス',
				branches: [
					{
						id: 57336683,
						shop_name: 'ガスト',
						url: 'https://www.ai-credit.com/article/gusto/',
						plans: [],
					},
					{
						id: 57338135,
						shop_name: 'バーミヤン',
						url: 'https://www.ai-credit.com/article/bamiyan/',
						plans: [],
					},
					{
						id: 57445294,
						shop_name: 'デニーズ',
						url: 'https://www.ai-credit.com/article/dennys-2/',
						plans: [],
					},
					{
						id: 13694711,
						shop_name: 'ロイヤルホスト',
						url: 'https://www.ai-credit.com/article/royalhost-otoku-siharai/',
						plans: [],
					},
					{
						id: 13694667,
						shop_name: 'サイゼリヤ',
						url: 'https://www.ai-credit.com/article/saizeriya/',
						plans: [],
					},
					{
						id: 57338477,
						shop_name: 'ジョナサン',
						url: '',
						plans: [],
					},
				],
			},
			{
				name: '100円均一・家具雑貨',
				branches: [
					{
						id: 842250,
						shop_name: 'ダイソー',
						url: 'https://www.ai-credit.com/article/daiso/',
						plans: [],
					},
					{
						id: 57346810,
						shop_name: 'Seria',
						url: 'https://www.ai-credit.com/article/seria/',
						plans: [],
					},
					{
						id: 57370811,
						shop_name: '無印良品',
						url: 'https://www.ai-credit.com/article/muji/',
						plans: [],
					},
					{
						id: 57348783,
						shop_name: 'ニトリ',
						url: 'https://www.ai-credit.com/article/nitori/',
						plans: [],
					},
				],
			},
			{
				name: '家電量販店',
				branches: [
					{
						id: 55427734,
						shop_name: 'ビックカメラ',
						url: 'https://www.ai-credit.com/article/biccamera/',
						plans: [],
					},
					{
						id: 55761980,
						shop_name: 'ヨドバシカメラ',
						url: 'https://www.ai-credit.com/article/homeappliances/',
						plans: [],
					},
					{
						id: 57389183,
						shop_name: 'ジョーシン',
						url: 'https://www.ai-credit.com/article/joshin-otoku-shiharai/',
						plans: [],
					},
					{
						id: 57335033,
						shop_name: 'エディオン',
						url: 'https://www.ai-credit.com/article/edion/',
						plans: [],
					},
					{
						id: 57456542,
						shop_name: 'ヤマダ電機',
						url: 'https://www.ai-credit.com/article/homeappliances/',
						plans: [],
					},
				],
			},
			{
				name: '居酒屋・焼肉',
				branches: [
					{
						id: 57373046,
						shop_name: '魚民',
						url: 'https://www.ai-credit.com/article/uotami-otoku-shiharai/',
						plans: [],
					},
					{
						id: 57434539,
						shop_name: '焼肉の和民',
						url: 'https://www.ai-credit.com/article/watami-otoku-shiharai/',
						plans: [],
					},
					{
						id: 57434607,
						shop_name: 'ミライザカ',
						url: '',
						plans: [],
					},
					{
						id: 13700918,
						shop_name: '安楽亭',
						url: '',
						plans: [],
					},
					{
						id: 55514163,
						shop_name: '牛角',
						url: '',
						plans: [],
					},
					{
						id: 57405725,
						shop_name: '鳥貴族',
						url: '',
						plans: [],
					},
				],
			},
			{
				name: 'ファーストフード',
				branches: [
					{
						id: 13694872,
						shop_name: 'マクドナルド',
						url: 'https://www.ai-credit.com/article/mcdonald/',
						plans: [],
					},
					{
						id: 13694900,
						shop_name: 'ロッテリア',
						url: '',
						plans: [],
					},
					{
						id: 13694903,
						shop_name: 'モスバーガー',
						url: '',
						plans: [],
					},
					{
						id: 13754222,
						shop_name: 'ファーストキッチン',
						url: '',
						plans: [],
					},
					{
						id: 57267384,
						shop_name: 'ケンタッキー',
						url: 'https://www.ai-credit.com/article/kfc-2/',
						plans: [],
					},
					/*
					{
						id: ,
						shop_name: 'フレッシュネスバーガー',
						url: '',
						plans: [],
					},
					*/
					{
						id: 57445919,
						shop_name: 'ミスタードーナツ',
						url: 'https://www.ai-credit.com/article/mister-donut/',
						plans: [],
					},
				],
			},
			{
				name: 'コーヒーショップ',
				branches: [
					{
						id: 13146,
						shop_name: 'ドトール',
						url: 'https://www.ai-credit.com/article/doutor/',
						plans: [],
					},
					{
						id: 57427645,
						shop_name: 'タリーズコーヒー',
						url: 'https://www.ai-credit.com/article/tullys/',
						plans: [],
					},
					{
						id: 73181,
						shop_name: 'スターバックス',
						url: '',
						plans: [],
					},
					{
						id: 57406657,
						shop_name: 'カフェ・ベローチェ',
						url: '',
						plans: [],
					},
					{
						id: 47449,
						shop_name: 'エクセルシオール',
						url: 'https://www.ai-credit.com/article/excelsiorcaffe/',
						plans: [],
					},
					{
						id: 65808,
						shop_name: 'ルノアール',
						url: '',
						plans: [],
					},
				],
			},
			{
				name: '子供服・アパレル・家具',
				branches: [
					{
						id: 57375321,
						shop_name: '西松屋',
						url: 'https://www.ai-credit.com/article/nishimatsuya/',
						plans: [],
					},
					{
						id: 55315052,
						shop_name: 'アカチャンホンポ',
						url: 'https://www.ai-credit.com/article/akachan-honpo/',
						plans: [],
					},
					{
						id: 57350430,
						shop_name: 'UNIQLO',
						url: '',
						plans: [],
					},
					{
						id: 57500688,
						shop_name: 'GU',
						url: 'https://www.ai-credit.com/article/gu/',
						plans: [],
					},
					{
						id: 57390118,
						shop_name: 'しまむら',
						url: 'https://www.ai-credit.com/article/fashion-shimamura/',
						plans: [],
					},
				],
			},
			{
				name: 'ネットショップ',
				branches: [
					{
						id: 57266758,
						shop_name: '楽天市場',
						url: 'https://www.ai-credit.com/article/rakuten-4/',
						plans: [],
					},
					{
						id: 57266759,
						shop_name: 'ZOZOTOWN',
						url: 'https://www.ai-credit.com/article/zozotown/',
						plans: [],
					},
					{
						id: 57266760,
						shop_name: 'Yahoo!ショッピング',
						url: 'https://www.ai-credit.com/article/yahoo-shopping/',
						plans: [],
					},
					{
						id: 57266763,
						shop_name: 'au PAYマーケット',
						url: 'https://www.ai-credit.com/article/aupay-market/',
						plans: [],
					},
					{
						id: 57266765,
						shop_name: 'Amazon',
						url: 'https://www.ai-credit.com/article/amazon/',
						plans: [],
					},
					/*
					{
						id: 57266761,
						shop_name: 'PayPayモール',
						url: 'https://www.ai-credit.com/article/paypay-mall/',
						plans: [],
					},
					*/
				],
			},
		])

		// スライダー
		const thumbsSwiper: any = ref(null)
		const setThumbsSwiper = (swiper: any) => {
			thumbsSwiper.value = swiper
		}

		const slideSwiper: any = ref(null)
		const setSlideSwiper = (swiper: any) => {
			slideSwiper.value = swiper
		}

		// 特集スライダー
		const featuresSwiper: any = ref(null)
		const setFeaturesSwiper = (swiper: any) => {
			featuresSwiper.value = swiper
		}

		// スライドが変更されたときにプランを取得
		const changePlanWhenSlideChange = () => {
			for (const branch of getPlans[thumbsSwiper.value.clickedIndex]) {
				branch()
			}
		}

		// スライドが変更されたときにプランを取得
		const changePlanWhenSlideMainChange = () => {
			for (const branch of getPlans[slideSwiper.value.activeIndex]) {
				branch()
			}
		}

		// router
		const router = useRouter()

		// store
		const store = useStore()

		const state = reactive({
			address: '',
			word: '',
		})
		const notices = reactive({
			data: [],
		})

		// バリデーション
		const rules = {
			address: { required },
		}
		const v$ = useVuelidate(rules, state)

		const signupDone = ref('0')
		if (router.currentRoute.value.query.signup_done === '1') {
			signupDone.value = router.currentRoute.value.query.signup_done as string
		}

		const loginDone = ref('0')
		if (router.currentRoute.value.query.login_done === '1') {
			loginDone.value = router.currentRoute.value.query.login_done as string
		}

		const deleteDialog = () => {
			signupDone.value = '0'
			loginDone.value = '0'
		}

		const otoku_application_url = ref('')

		const authenticated_phone_number = ref(0)

		// url取得
		const getOtokuApplicationUrl = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.get('/api2/affiliate_item/get_otoku_application_url')
				otoku_application_url.value = res.data.affiliate_item_otoku_application_url
				authenticated_phone_number.value = 1
			} catch (error: any) {
				if (error.response?.data.message.startsWith('電話番号認証')) {
					authenticated_phone_number.value = 0
				}
			}
		}
		getOtokuApplicationUrl()

		const pomochi_game_url = ref('')

		// url取得
		const getPomochiGameUrl = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.get('/api2/affiliate_item/get_mychips_ow_url')
				pomochi_game_url.value = res.data.mychips_ow_url
				authenticated_phone_number.value = 1
			} catch (error: any) {
				if (error.response?.data.message.startsWith('電話番号認証')) {
					authenticated_phone_number.value = 0
				}
			}
		}
		getPomochiGameUrl()

		// 公式サイトへ押下
		const checkAuthModal = () => {
			if (!store.state.isLoggedIn) {
				const popup = document.getElementById('report-popup01')
				if (popup) {
					popup.classList.add('is-show')
				}
				return
			}

			if (authenticated_phone_number.value === 0) {
				const popup = document.getElementById('report-popup01')
				if (popup) {
					popup.classList.add('is-show')
				}
				return
			}
		}
		// ポップアップ「はい」押下
		const checkAuthYes = () => {
			const popup = document.getElementById('report-popup01')
			if (popup) {
				popup.classList.remove('is-show')
			}
			if (!store.state.isLoggedIn) {
				return router.push({ name: 'Login' })
			} else {
				return router.push({ name: 'UserCertification' })
			}
		}

		// ポップアップ「いいえ」または枠外押下
		const checkAuthModalClose = () => {
			const popup = document.getElementById('report-popup01')
			if (popup) {
				popup.classList.remove('is-show')
			}
		}

		const getNotices = async () => {
			axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
			const result = await axios.get('/api2/notice/index?type=0&page=1')
			const res = result.data.notices
			for (let i = 0; i < result.data.notices.length; i++) {
				if (res[i].type === 1) {
					res[i].typeja = 'お得情報'
				} else if (res[i].type === 2) {
					res[i].typeja = 'アプリ情報'
				} else if (res[i].type === 3) {
					res[i].typeja = 'ポイント情報'
				} else {
					res[i].typeja = ''
				}
				res[i].showed_at = res[i].showed_at.substring(0, 10)
			}
			// 上から5つのみに絞る
			notices.data = res.slice(0, 5)
		}

		const affiliateItems = reactive({
			features: [],
			recommends: [],
			word: '',
			tags: [],
		})

		// 特集案件取得
		const getFeatureAffiliateItems = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.get('/api2/feature/index')
				affiliateItems.features = res.data.features.filter((item: any) => item.feature_place_id === 6)
			} catch (error) {
				if (axios.isAxiosError(error)) {
					alert(error.response?.data.message)
				}
			}
		}

		// イチオシ案件取得
		const getReccomendAffiliateItems = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.get('/api2/affiliate_item/index', {
					params: {
						only_recommend: true,
					},
				})
				affiliateItems.recommends = res.data.affiliates
				affiliateItems.tags = res.data.affiliate_category_tags
			} catch (error) {
				if (axios.isAxiosError(error)) {
					alert(error.response?.data.message)
				}
			}
		}

		// フリーワードで案件一覧へ遷移
		const getWordAffiliateItems = () => {
			return router.push({
				name: 'AffiliateItemIndex',
				params: { page: 1 },
				query: { word: affiliateItems.word },
			})
		}

		const search = async () => {
			try {
				const searchParams = state.address
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const latlong = await axios.get('/api2/address/get_latitude_longitude?word="' + searchParams + '"')
				const latitude = latlong['data'].latitude
				const longitude = latlong['data'].longitude
				router.push({
					name: 'BranchIndex',
					query: { word: state.word, address: state.address, latitude: latitude, longitude: longitude },
				})
			} catch (error) {
				window.alert('正確な地名・駅名を入力してください')
			}
		}

		// プラン取得
		const getPlans: any[][] = []
		for (let branchesCategoriesIndex in branchesCategories) {
			getPlans[branchesCategoriesIndex] = []
			for (let branchesIndex in branchesCategories[branchesCategoriesIndex].branches) {
				getPlans[branchesCategoriesIndex][branchesIndex] = async () => {
					// すでに取得済みのときはスキップ
					if (branchesCategories[branchesCategoriesIndex].branches[branchesIndex].plans.length > 0) {
						return
					}

					axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
					const res = await axios.get('/api2/plan/index', {
						params: {
							branch_id: branchesCategories[branchesCategoriesIndex].branches[branchesIndex].id,
							price: 1000,
						},
					})

					// 還元率で降順ソート
					res.data['plans'].sort(function (a: any, b: any) {
						if (a.reduction_rate > b.reduction_rate) return -1
						if (a.reduction_rate < b.reduction_rate) return 1
						return 0
					})

					// 還元率上位5つ以外削除
					res.data['plans'].splice(5)

					// 支払い方法
					for (let i = 0; i < res.data.plans.length; i++) {
						// 還元額計算
						if (res.data['plans'][i] !== null) {
							res.data['plans'][i].reduction_prace = Math.floor(Number(res.data['plans'][i].reduction_rate) * 1000)
						}
						// 支払い種類の文字列生成
						res.data['plans'][i].item_name = []
						for (let j = 0; j < res.data['plans'][i].items.length; j++) {
							res.data['plans'][i].item_name.push(res.data['plans'][i].items[j])
						}
						const tmpStr = res.data['plans'][i].item_name.join(',')
						res.data['plans'][i].item_name_str = tmpStr.replace(',', '/')
					}
					branchesCategories[branchesCategoriesIndex].branches[branchesIndex].plans = res.data['plans']
				}
			}
		}

		// デバイストークン取得後にその他API起動
		const { deviceToken } = getDeviceToken()
		const start = async () => {
			await deviceToken()
			await getFeatureAffiliateItems()
			await getReccomendAffiliateItems()
			await getNotices()
			// カテゴリ1つ目の店舗は全て取得して表示しておく
			for (const branch of getPlans[0]) {
				branch()
			}
		}
		start()

		const windowWidth = window.innerWidth

		return {
			store,
			state,
			v$,
			notices,
			affiliateItems,
			getWordAffiliateItems,
			branchesCategories,
			signupDone,
			loginDone,
			deleteDialog,
			search,
			changePlanWhenSlideChange,
			changePlanWhenSlideMainChange,
			getNotices,
			Thumbs,
			thumbsSwiper,
			setThumbsSwiper,
			slideSwiper,
			setSlideSwiper,
			setFeaturesSwiper,
			windowWidth,
			otoku_application_url,
			pomochi_game_url,
			authenticated_phone_number,
			checkAuthModal,
			checkAuthYes,
			checkAuthModalClose,
		}
	},
})
